/* eslint-disable react/no-unescaped-entities */
import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CookieConsent from 'react-cookie-consent';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import SEO from '../components/seo';
import Layout from '../components/Layout';

class cookiePage extends PureComponent {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      cookieState: cookies.get('BprgCookie') === 'true' ? 'yes' : 'no',
    };
  }

  // componentDidMount() {
  //   // window['ga-disable-UA-138584690-1'] = true;

  //   const {
  //     name: { BprgCookie },
  //   } = this.state;

  //   console.log(BprgCookie)
  //   if (BprgCookie) {
  //     this.setState({
  //       cookieState: 'yes',
  //     });
  //   } else if(!BprgCookie) {
  //     this.setState({
  //       cookieState: 'no',
  //     });
  //   }
  // }

  render() {
    const cookies = new Cookies();
    const { cookieState } = this.state;

    // console.log(Cookies);

    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Privacy & Cookies Notice" />
        <Container className="main_wrapper section-one pt-3 pt-lg-5">
          <Row>
            <Col xs={12}>
              <h3 className="w-100 text-center text-uppercase">Privacy & cookies notice</h3>
              <p className=" mt-4 mb-0">
                This notice explains how we use cookies and similar technologies on our website
                applications to help provide you with the best possible online experience.
              </p>
            </Col>
            <Col xs={12}>
              <div className="my-4">
                <h5>Your online privacy</h5>
                <p className="mb-2">
                  You can visit our website without telling us who you are or revealing any
                  information about yourself.
                </p>
                <p className="mb-2">
                  We promise to treat any personal information about you securely, fairly and
                  lawfully. We are committed to protecting your privacy.
                </p>
                <p className="mb-2">
                  When we ask you for personal information online it will only be in response to you
                  actively applying for a role or requesting our products or services.
                </p>
              </div>

              <div className="my-4">
                <h5>Cookies – what are they?</h5>
                <p className="mb-2">
                  A "cookie" is a small text file that's stored on your computer, smartphone,
                  tablet, or other device when you visit a website or use an app.
                </p>
                <p className="mb-2">
                  Some cookies are deleted when you close down your browser. These are known as 
                  <span className="text-bold">session cookies</span>. Others remain on your device
                  until they expire or you delete them from your cache. These are known as 
                  <span className="text-bold">persistent cookies</span> and enable us to remember
                  things about you as a returning visitor.
                </p>
                <p className="mb-2">This website uses session and persistent cookies.</p>
              </div>

              <div className="my-4">
                <h5>How to control and delete cookies</h5>
                <p className="mb-2">
                  If you want to restrict or block the cookies we set, you can do this through your
                  browser settings. The ‘help’ function within your browser should tell you how.
                </p>
                <p className="mb-2">
                  To find out more about cookies, including how to see what cookies have been set
                  and how to manage and delete them, visit 
                  <span>
                    <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>
                  </span>
                  . Alternatively, you can search the internet for other independent information on
                  cookies.
                </p>
              </div>

              <div className="my-4">
                <h5>Cookies – how we use them</h5>
                <p className="mb-2">
                  If you delete cookies relating to this website we will not remember things about
                  you, including your cookie preferences, and you will be treated as a first-time
                  visitor the next time you visit the site.
                </p>
                <p className="mb-2">
                  We use cookies (and other similar technologies) to give you a better online
                  experience and track website performance.
                </p>
              </div>

              <div className="border border-secondary mb-3 p-3 pb-5">
                <div>
                  <h5>Are you happy for us to use cookies?</h5>
                  <p className="mb-2">
                    Some features that are designed to give you a better online experience only work
                    if we use cookies.
                  </p>
                  <p className="mb-2">
                    You can change your mind at any time but, for now, are you happy for us to store
                    and access cookies on your device for the purposes described?
                  </p>
                </div>
                <CookieConsent
                  location="bottom"
                  cookieName="BprgCookie"
                  style={{ display: 'none' }}
                  acceptOnScroll
                  acceptOnScrollPercentage={0.0001}
                  onAccept={() => {
                    window['ga-disable-UA-138584690-1'] = false;
                    this.setState({
                      cookieState: 'yes',
                    });
                    window.location.reload();
                  }}
                />
                <CookieConsent
                  location="bottom"
                  cookieName="BprgCookie"
                  enableDeclineButton
                  containerClasses="cookie_bar_button pb-4"
                  flipButtons
                  buttonText="Yes"
                  declineButtonText="No"
                  buttonClasses={`btn ${
                    cookieState === 'yes' ? 'btn-info' : 'btn-outline-info'
                  } mr-3`}
                  declineButtonClasses={`btn ${
                    cookieState === 'no' ? 'btn-info' : 'btn-outline-info'
                  }`}
                  hideOnAccept={false}
                  hideOnDecline={false}
                  disableStyles
                  debug
                  style={{ background: 'none' }}
                  onAccept={() => {
                    window['ga-disable-UA-138584690-1'] = false;
                    this.setState({
                      cookieState: 'yes',
                    });
                    window.location.reload();
                  }}
                  onDecline={() => {
                    window['ga-disable-UA-138584690-1'] = true;
                    cookies.remove('_ga');
                    cookies.remove('_gid');
                    this.setState({
                      cookieState: 'no',
                    });
                    window.location.reload();
                  }}
                  expires={150}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default withCookies(cookiePage);
